const LogoGreen = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="153"
            height="34"
            viewBox="0 0 153 34"
            fill="none"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M0 24.5833L5.11399 0H23.2891L22.4386 4.11013H9.31122L8.14041 9.69371H20.8425L19.9921 13.8038H7.28992L5.7491 20.4953H20.0141L19.1416 24.6054L0 24.5833Z"
                    fill="#009900"
                />
                <path
                    d="M25.8628 6.77441H30.662L28.453 17.299C28.2643 18.0769 28.1314 18.8673 28.0553 19.6643C28.0506 19.9113 28.0973 20.1566 28.1924 20.3846C28.2875 20.6125 28.4289 20.8181 28.6076 20.9882C28.8014 21.1735 29.0303 21.318 29.2807 21.4131C29.5311 21.5083 29.798 21.5521 30.0656 21.5421C30.4952 21.5422 30.9208 21.4594 31.3192 21.2984C31.775 21.1137 32.2026 20.8657 32.5894 20.5616C33.0218 20.2398 33.4148 19.868 33.7602 19.4538C34.1541 18.9451 34.4791 18.3863 34.7267 17.792C35.026 16.9744 35.2569 16.1332 35.417 15.2772L37.1843 6.79103H41.9835L38.2999 24.5831H33.821L34.3236 22.157C32.3649 24.044 30.2294 24.9894 27.9173 24.9931C27.2888 25.0276 26.6599 24.934 26.0685 24.7179C25.4771 24.5018 24.9356 24.1676 24.4766 23.7356C24.0498 23.2885 23.7184 22.7587 23.5028 22.1787C23.2873 21.5986 23.192 20.9805 23.223 20.3622C23.3064 19.1249 23.4911 17.8966 23.7753 16.6897L25.8628 6.77441Z"
                    fill="#009900"
                />
                <path
                    d="M45.9212 6.7745H50.4056L49.7042 10.231C51.3794 7.64601 53.1909 6.35352 55.1385 6.35352C55.9025 6.37513 56.654 6.55228 57.3476 6.87421L55.5086 10.8182C55.0643 10.6568 54.5949 10.5761 54.1224 10.58C53.2038 10.6141 52.3207 10.9443 51.604 11.5216C50.7347 12.1706 50.0463 13.0328 49.6048 14.0254C48.9634 15.6342 48.4878 17.3047 48.1855 19.0107L47.0092 24.5832H42.21L45.9212 6.7745Z"
                    fill="#009900"
                />
                <path
                    d="M55.8839 17.1717C55.8839 13.874 56.8043 11.2447 58.6452 9.2838C60.4861 7.3229 62.9253 6.34061 65.9627 6.33691C68.5805 6.33691 70.6257 7.06071 72.0984 8.50831C73.5711 9.9559 74.3075 11.9039 74.3075 14.3522C74.3554 17.1058 73.3698 19.7769 71.5461 21.8358C69.7053 23.937 67.2421 24.9876 64.1568 24.9876C62.6247 25.0124 61.1093 24.6666 59.7387 23.9795C58.4951 23.363 57.4809 22.3632 56.8448 21.1267C56.2083 19.9065 55.8784 18.5489 55.8839 17.1717ZM69.5856 13.7484C69.6095 13.2485 69.5333 12.7488 69.3617 12.2789C69.19 11.8089 68.9262 11.3782 68.586 11.0121C68.253 10.6651 67.8505 10.3929 67.405 10.2134C66.9595 10.0339 66.4811 9.95116 66.0014 9.97067C64.9971 9.95924 64.0203 10.3002 63.2401 10.9345C62.3707 11.6782 61.7229 12.6481 61.3679 13.7374C60.9494 14.8157 60.7232 15.9594 60.6996 17.1163C60.642 18.2247 61.0141 19.3124 61.7379 20.1518C62.0676 20.5134 62.4711 20.7995 62.9209 20.9907C63.3707 21.1818 63.8563 21.2736 64.3446 21.2597C64.9915 21.262 65.6297 21.1095 66.206 20.8147C66.7824 20.5199 67.2802 20.0913 67.6582 19.5647C68.9555 17.9098 69.637 15.853 69.5856 13.7484Z"
                    fill="#009900"
                />
                <path
                    d="M79.5925 6.7746H84.1377L83.7566 8.60256C84.5736 7.8942 85.4912 7.31173 86.4793 6.87431C87.3445 6.52501 88.2693 6.34816 89.2019 6.35362C90.0483 6.32378 90.8905 6.48384 91.6673 6.82212C92.4441 7.16041 93.1358 7.66839 93.6919 8.30898C94.8222 9.59409 95.3892 11.5107 95.3928 14.0587C95.4653 17.031 94.4204 19.9219 92.4658 22.1571C91.7449 23.0385 90.8391 23.7496 89.813 24.2398C88.7868 24.73 87.6655 24.9873 86.529 24.9932C85.4659 25.0467 84.4082 24.8115 83.4673 24.3123C82.5264 23.8132 81.7372 23.0686 81.183 22.1571L78.7089 34.0222H73.9097L79.5925 6.7746ZM82.4809 16.989C82.4073 18.1531 82.7696 19.3028 83.497 20.2129C83.7832 20.5705 84.1454 20.8596 84.5572 21.0589C84.969 21.2583 85.42 21.3629 85.8773 21.365C86.7178 21.346 87.5266 21.0391 88.1692 20.4954C89.0373 19.7301 89.6776 18.739 90.0193 17.6316C90.4767 16.4467 90.7235 15.1906 90.7483 13.9203C90.8226 12.8153 90.4818 11.7225 89.7929 10.857C89.4914 10.5121 89.1179 10.238 88.6988 10.0542C88.2798 9.87039 87.8256 9.78138 87.3684 9.7935C86.4438 9.79801 85.554 10.1475 84.8722 10.774C84.0388 11.5592 83.4337 12.5566 83.1215 13.6599C82.7389 14.7301 82.5228 15.8529 82.4809 16.989Z"
                    fill="#009900"
                />
                <path
                    d="M109.702 17.8752L114.617 18.6286C113.928 20.5455 112.667 22.203 111.006 23.3757C109.376 24.4758 107.447 25.0445 105.483 25.0043C103.101 25.0043 101.21 24.3266 99.8111 22.9714C98.4121 21.6161 97.7254 19.7549 97.7512 17.3878C97.7449 15.4858 98.1798 13.6083 99.0214 11.9039C99.8593 10.1944 101.191 8.77763 102.843 7.8381C104.546 6.85146 106.482 6.33896 108.449 6.35358C110.658 6.35358 112.419 6.9075 113.734 8.01535C114.377 8.54737 114.904 9.20557 115.284 9.94953C115.664 10.6935 115.888 11.5075 115.943 12.3415L111.133 12.829C111.098 11.9964 110.755 11.2066 110.172 10.6133C109.602 10.1281 108.87 9.87684 108.123 9.90978C107.108 9.91432 106.128 10.2838 105.361 10.9512C104.429 11.7776 103.754 12.8567 103.417 14.0587C102.988 15.307 102.759 16.6158 102.738 17.9362C102.682 18.821 102.971 19.6932 103.544 20.3679C103.803 20.6462 104.117 20.8666 104.466 21.0146C104.816 21.1626 105.192 21.2348 105.571 21.2265C106.424 21.2106 107.249 20.9149 107.918 20.3845C108.742 19.7383 109.362 18.8668 109.702 17.8752Z"
                    fill="#009900"
                />
                <path
                    d="M124.679 11.8264L119.676 11.422C120.214 9.87123 121.272 8.55568 122.669 7.69966C124.094 6.80229 125.981 6.35546 128.33 6.35915C130.771 6.35915 132.577 6.81891 133.748 7.73843C134.286 8.12215 134.725 8.62865 135.029 9.21614C135.334 9.80364 135.495 10.4553 135.498 11.1174C135.496 11.6913 135.444 12.264 135.344 12.829C135.244 13.4346 134.891 15.0226 134.283 17.5928C133.887 19.0506 133.635 20.5443 133.532 22.0519C133.548 22.9126 133.694 23.766 133.963 24.5833H128.993C128.792 23.9295 128.666 23.2549 128.617 22.5726C127.877 23.3434 126.988 23.9558 126.005 24.3728C125.074 24.7794 124.071 24.9923 123.056 24.9988C121.616 25.0509 120.214 24.5291 119.157 23.5475C118.654 23.0714 118.257 22.4931 117.994 21.8512C117.732 21.2093 117.608 20.5186 117.633 19.8251C117.596 19.0655 117.725 18.307 118.011 17.6028C118.297 16.8986 118.734 16.2656 119.289 15.7482C120.416 14.729 122.428 14.1141 125.326 13.9036C126.977 13.8337 128.617 13.6037 130.224 13.2168C130.41 12.6984 130.518 12.1551 130.545 11.6048C130.545 11.3529 130.485 11.1044 130.371 10.88C130.257 10.6556 130.091 10.4617 129.887 10.3142C129.307 9.92917 128.616 9.74622 127.921 9.7935C127.157 9.7582 126.4 9.95097 125.745 10.3474C125.226 10.6971 124.847 11.2217 124.679 11.8264ZM129.562 16.2578C129.155 16.3529 128.744 16.425 128.33 16.4738C125.756 16.7674 124.072 17.1884 123.288 17.7479C123.021 17.9273 122.803 18.1719 122.657 18.4588C122.51 18.7456 122.438 19.0652 122.448 19.3875C122.444 19.6566 122.5 19.9232 122.611 20.1684C122.721 20.4135 122.885 20.6311 123.089 20.8056C123.567 21.1969 124.174 21.3946 124.79 21.3595C125.56 21.3566 126.318 21.1665 126.999 20.8056C127.627 20.5034 128.16 20.0339 128.54 19.4484C128.972 18.6362 129.275 17.7617 129.44 16.8561L129.562 16.2578Z"
                    fill="#009900"
                />
                <path
                    d="M141.585 6.7745H146.064L145.346 10.231C147.017 7.64601 148.829 6.35352 150.78 6.35352C151.544 6.37452 152.296 6.5517 152.989 6.87421L151.15 10.8182C150.706 10.6563 150.236 10.5757 149.764 10.58C148.847 10.6145 147.966 10.9448 147.251 11.5216C146.378 12.1685 145.688 13.0311 145.246 14.0254C144.6 15.6325 144.124 17.3035 143.827 19.0107L142.656 24.5943H137.857L141.585 6.7745Z"
                    fill="#009900"
                />
                <path
                    d="M73.9209 33.9999H0C0 33.9999 32.523 29.8123 74.7935 29.8123L73.9209 33.9999Z"
                    fill="#FFDC00"
                />
                <path
                    d="M82.9063 29.8122C82.9063 29.8122 121.471 29.5685 151.575 33.9999H82.0337L82.9063 29.8122Z"
                    fill="#FFDC00"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="153" height="34" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoGreen
